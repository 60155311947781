import gsap from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__BlockDance extends VScroll_Item {
  _bg;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._bg = GetBy.selector('img', __link)[0];

    this.onShow = () => { };
    this.onMove = () => {
      if (isMobile) return;

      const y = Maths.clamp(Maths.map(this.progress, 0, .5, 100, 0), 0, 100);
      gsap.set(this._bg, { y: `${y}%` });
    };
    this.onHide = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('block-dance', ScrollItem__BlockDance);
