import gsap from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__BlockLanding extends VScroll_Item {
  _targetDate;
  _countdown;
  _wrapper;
  _bg;
  _calls = {
    loop: () => this.loop()
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._bg = GetBy.selector('img', __link)[0];
    this._wrapper = GetBy.class('wrapper', __link)[0];
    gsap.set(this._wrapper, { opacity: 0 });

    this._countdown = GetBy.class('__countdown', __link)[0];
    this._targetDate = '2024-09-21T17:30:00';
    this.doTime();

    this.onShow = () => {
      gsap.ticker.add(this._calls.loop);
      gsap.to(this._wrapper, { opacity: 1, duration: .5 });

    };
    this.onMove = () => {
      if (isMobile) return;

      const p = Maths.clamp(Maths.map(this.progress, .5, 1, 1, 1.4), 1, 1.4);
      gsap.set(this._wrapper, { scaleX: p, scaleY: p });

      const p2 = Maths.clamp(Maths.map(this.progress, .5, 1, 1, 1.6), 1, 1.6);
      const o = Maths.clamp(Maths.map(this.progress, .5, 1, 1, 0), 0, 1);
      gsap.set(this._bg, { scaleX: p2, scaleY: p2, opacity: o });
    };
    this.onHide = () => {
      gsap.ticker.remove(this._calls.loop);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    this.doTime();
  }

  doTime() {
    const targetDate = new Date(this._targetDate).getTime();
    const now = Date.now();
    const distance = targetDate - now;

    if (distance < 0) {
      this._countdown.innerHTML = "Benvinguts!";
      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this._countdown.innerHTML = days + "d, " + hours + "h:" + minutes + "m:" + seconds + "s";
  }
}

Scroll._registerClass('block-landing', ScrollItem__BlockLanding);
