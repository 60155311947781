import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _img;

    static init() {
        this._container = GetBy.id('Preloader');
        this._img = GetBy.selector('img', this._container);
    }

    static update(__progress) { }

    static hide(__call) {
        gsap.to(this._img, {
            duration: 1,
            opacity: 1,
        });
        gsap.to(this._container, {
            duration: .5,
            opacity: 0,
            delay: .5,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
