import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__SliderDefault extends VScroll_Item {
    _call;
    _slider;
    _current;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._current = GetBy.class('current', __link)[0];

        if (!isMobile) {
            this._slider = new SliderScroll(__link, {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            });
        }

        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (isMobile) return;

        if (this._slider) {
            this._slider.loop();
            this._current.innerHTML = Math.max(Math.ceil(Maths.lerp(0, this._slider.items.length, this._slider.progress)), 1);
        }
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    hasHiddenEnabled = false;
    info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
}

Scroll._registerClass('SliderDefault', ScrollItem__SliderDefault);
